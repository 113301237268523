import dissoc from 'ramda/es/dissoc'
import { cityByLocation } from '../../util/google-api'

const initialState = {
  distance: 10,
  order: 'asc',
  orderby: 'distance',
  address: '',
  location: null,
  produktgruppe: '',
}

const UPDATE_FILTER = 'dhsv/nir001/filters/UPDATE_FILTER'

export default (state = initialState, action = {}) => {
  const { type, payload = {} } = action

  switch (type) {
    case UPDATE_FILTER:
      return payload.value
        ? {
            ...state,
            [payload.name]: payload.value,
          }
        : dissoc(payload.name, state)
    default:
      return state
  }
}

export const updateFilter = (name, value) => dispatch => {
  if (name === 'location' && !!value) {
    cityByLocation(value).then(city =>
      dispatch({
        type: UPDATE_FILTER,
        payload: { name: 'address', value: city },
      })
    )
  }

  return dispatch({
    type: UPDATE_FILTER,
    payload: { name, value },
  })
}
