import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import './styles.scss'

export class Toggle extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      disabled,
      name,
      value,
      option1,
      option2,
      onChange,
      touched,
      error,
    } = this.props

    return (
      <div
        className={`dhsv_formcontrol_toggle ${
          touched && error ? 'has-error' : ''
        } ${value === option2 ? 'is-checked' : ''}`}
      >
        <label>
          <input
            disabled={disabled}
            name={name}
            value={value}
            checked={value === option2}
            onChange={e => onChange(e.target.checked ? option2 : option1)}
            type={'checkbox'}
          />
          <div className="toggle-wrapper">
            <div className="toggle1">{option1}</div>
            <div className="toggler" />
            <div className="toggle2">{option2}</div>
          </div>
        </label>
      </div>
    )
  }
}

Toggle.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  option1: PropTypes.string,
  option2: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

const ToggleReduxForm = ({
  input: { name, onChange, value },
  meta: { error, touched },
  option1,
  option2,
  ...other
}) => (
  <Toggle
    name={name}
    value={value}
    option1={option1}
    option2={option2}
    onChange={onChange}
    touched={touched}
    error={error}
    {...other}
  />
)

ToggleReduxForm.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
  option1: PropTypes.string.isRequired,
  option2: PropTypes.string.isRequired,
}

export default ToggleReduxForm
