import { connect } from 'react-redux'
import Component from './component'
import PropTypes from 'prop-types'
import { sIsSM } from '../../redux/modules/browser'

const WithData = connect(state => ({
  isSM: sIsSM(state),
}))(Component)

WithData.propTypes = {
  isSM: PropTypes.bool,
}

export default WithData
