/*
 inject a "label" prop based on the passed in "name" prop
 */
import React from 'react'
import PropTypes from 'prop-types'
import fieldLabels from '../shared/labels'
import omit from 'ramda/es/omit'

const withLabel = (WrappedComponent, labels = fieldLabels) => {
  const WithLabel = props => {
    const { name, required, label: customLabel } = props
    const label = name ? (labels[name] ? labels[name] : name) : ''
    const finalLabel = customLabel
      ? customLabel
      : label + (required ? ' *' : '')

    const passProps = omit(['label'], props)
    return <WrappedComponent placeholder={finalLabel} {...passProps} />
  }

  WithLabel.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool,
  }
  return WithLabel
}

export default withLabel
