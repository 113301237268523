import { combineReducers } from 'redux'
import browser from './browser'
import router from './router'
import form from 'redux-form/es/reducer'
import networkError from './networkError'
import filters from './filters'
import posts from './posts'

const appReducer = combineReducers({
  browser,
  location: router,
  form,
  networkError,
  filters,
  posts,
})

export default appReducer
