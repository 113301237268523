import { buildQueryString } from '../../util/string'
import { devMode } from '../../util/env'

const initialState = {
  abortController: null,
  isLoading: false,
  firstPageLoaded: false,
  error: null,
  data: [],
  page: 0,
  totalPosts: null,
  totalPages: null,
  query: '',
  selectedPostId: null,
}

const LOAD_POSTS = 'dhsv/nir001/posts/LOAD_POSTS'
const LOAD_POSTS_SUCCESS = 'dhsv/nir001/posts/LOAD_POSTS_SUCCESS'
const LOAD_SINGLE_POST_SUCCESS = 'dhsv/nir001/posts/LOAD_SINGLE_POST_SUCCESS'
const LOAD_POSTS_FAILURE = 'dhsv/nir001/posts/LOAD_POSTS_FAILURE'
const LOAD_POSTS_ABORTED = 'dhsv/nir001/posts/LOAD_POSTS_ABORTED'
const SELECT_POST = 'dhsv/nir001/posts/SELECT_POST'

export default (state = initialState, action = {}) => {
  const { data } = state
  const { type, payload = {} } = action

  switch (type) {
    case LOAD_POSTS:
      return {
        ...state,
        abortController: payload.abortController,
        isLoading: true,
      }
    case LOAD_POSTS_ABORTED:
      return {
        ...state,
        isLoading: false,
      }
    case LOAD_POSTS_SUCCESS:
      return {
        ...state,
        abortController: null,
        isLoading: false,
        data: payload.page === 1 ? payload.data : [...data, ...payload.data],
        totalPosts: parseInt(payload.headers.get('x-wp-total'), 10),
        totalPages: parseInt(payload.headers.get('x-wp-totalpages'), 10),
        page: payload.page,
        firstPageLoaded: true,
        query: payload.query,
      }
    case LOAD_SINGLE_POST_SUCCESS:
      return {
        ...state,
        abortController: null,
        isLoading: false,
        firstPageLoaded: true,
        data: payload.data,
      }
    case LOAD_POSTS_FAILURE:
      return {
        ...state,
        abortController: null,
        isLoading: false,
        error: payload.error,
      }
    case SELECT_POST:
      return { ...state, selectedPostId: payload.id }
    default:
      return state
  }
}

export const selectPost = (id = null) => ({
  type: SELECT_POST,
  payload: { id },
})

export const loadPostsContinously = () => (dispatch, getState) => {
  if (devMode) console.log('loadPostsContinously()') //eslint-disable-line
  const state = getState()
  const {
    posts: { page: lastPage, query: lastQuery, totalPages },
    filters,
  } = state

  const queryVars = {
    per_page: 10,
    ...filters,
  }

  const query = buildQueryString(queryVars)

  if (lastPage < totalPages || query !== lastQuery)
    return dispatch(loadPosts(queryVars))
      .then(() => dispatch(loadPostsContinously()))
      .catch(() => {})
}

export const loadPosts = queryVars => (dispatch, getState) => {
  const state = getState()
  const {
    posts: {
      abortController: previousAbortController,
      page: lastPage,
      query: lastQuery,
    },
  } = state

  const query = buildQueryString(queryVars)

  const page = lastQuery === query ? lastPage + 1 : 1

  if (devMode) console.log(`loadPosts() page #${page}`) //eslint-disable-line

  if (previousAbortController) {
    if (devMode) console.log('about to abort request, new query:') //eslint-disable-line
    if (devMode) console.log(query) //eslint-disable-line
    previousAbortController.abort()
  }

  let abortController
  let signal

  if (typeof window.AbortController !== 'undefined') {
    abortController = new window.AbortController()
    signal = abortController.signal
    signal.addEventListener('abort', () => {
      if (devMode) console.log(`request aborted:`) //eslint-disable-line
      if (devMode) console.log(query) //eslint-disable-line
      dispatch({ type: LOAD_POSTS_ABORTED })
    })
  }

  dispatch({
    type: LOAD_POSTS,
    payload: { abortController, page, query },
  })

  return fetch(`/wp-json/wp/v2/haendler?${query}&page=${page}`, { signal })
    .then(response => {
      if (response.status !== 200) {
        const message = response.statusText
          ? response.statusText
          : response.status

        throw new Error(message)
      }

      return response.json().then(json => {
        dispatch({
          payload: {
            data: json,
            headers: response.headers,
            page,
            query,
          },
          type: LOAD_POSTS_SUCCESS,
        })

        return json
      })
    })
    .catch(error => {
      // 20 === AbortError
      if (error.code !== 20) {
        if (devMode) console.log({ error }) //eslint-disable-line
        dispatch({
          payload: {
            error: error,
          },
          type: LOAD_POSTS_FAILURE,
        })
      }
      throw error
    })
}
