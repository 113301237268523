import { connect } from 'react-redux'
import { createSelector } from 'reselect'
import Component from './component'

const mkSErrors = formName =>
  createSelector(
    state => state.form[formName] && state.form[formName].fields,
    (_, props) => props.error,
    (fields, errors) =>
      fields && Array.isArray(errors)
        ? errors.filter(
            ([key]) =>
              key.includes('_server_error') ||
              (fields && fields[key] && fields[key].touched)
          )
        : []
  )

const sErrorsContact = mkSErrors('contact')

export const ValidationMessagesContact = connect((state, props) => ({
  errors: sErrorsContact(state, props),
}))(Component)
