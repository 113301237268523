import React from 'react'
import PropTypes from 'prop-types'
import pick from 'ramda/es/pick'
import './styles.scss'

class Button extends React.Component {
  render() {
    const { children, className, type, bootstrapBtn } = this.props

    const propsToPass = pick(
      ['data-hover', 'disabled', 'onClick', 'style', 'title', 'type'],
      this.props
    )

    return (
      <button
        className={`dhsv_formcontrol_button btn btn-${bootstrapBtn} ${
          className || ''
        }`}
        type={type ? type : 'button'}
        {...propsToPass}
      >
        {children}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  'data-hover': PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
  bootstrapBtn: PropTypes.oneOf([
    'default',
    'primary',
    'danger',
    'info',
    'warning',
  ]),
}

Button.defaultProps = {
  bootstrapBtn: 'default',
}

export default Button
