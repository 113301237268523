import React from 'react'
import PropTypes from 'prop-types'
import {
  fieldInputPropTypes,
  fieldMetaPropTypes,
} from 'redux-form/es/propTypes'
import './styles.scss'

export class Radio extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      disabled,
      name,
      value,
      onChange,
      currentValue,
      touched,
      error,
    } = this.props

    return (
      <div
        className={`dhsv_formcontrol_radio ${
          touched && error ? 'has-error' : ''
        } ${value ? 'has-value' : ''}`}
      >
        <input
          disabled={disabled}
          id={`${name}-${value}`}
          name={name}
          value={value}
          checked={value === currentValue}
          onChange={() => onChange(value)}
          type={'radio'}
        />
        <label
          htmlFor={`${name}-${value}`}
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
    )
  }
}

Radio.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  currentValue: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

const RadiosReduxForm = ({
  input: { name, onChange, value: currentValue },
  meta: { error, touched },
  options,
  ...other
}) => (
  <div className="dhsv_formcontrol_radios">
    {options.map((item, i) => (
      <Radio
        key={i}
        name={name}
        value={item.value}
        onChange={onChange}
        currentValue={currentValue}
        touched={touched}
        error={error}
        {...other}
      />
    ))}
  </div>
)

RadiosReduxForm.propTypes = {
  input: PropTypes.shape(fieldInputPropTypes),
  meta: PropTypes.shape(fieldMetaPropTypes),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
    })
  ),
}

export default RadiosReduxForm
