import React from 'react'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import './styles.scss'

class ImageCtnSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.flkty.on('ready', () => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'))
      }, 1000)
    })
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent('resize'))
  }

  render() {
    const { type, slides } = this.props

    const flickityOptions = {
      cellSelector: '.slide',
      draggable: slides.length > 1 ? true : false,
      pageDots: true,
      prevNextButtons: true,
      arrowShape: {
        x0: 8,
        x1: 50,
        y1: 45,
        x2: 51,
        y2: 44,
        x3: 9.8,
      },
      contain: true,
      freeScroll: false,
      autoPlay: 5000,
      pauseAutoPlayOnHover: false,
      wrapAround: true,
    }

    return (
      <div className="dhsv_image_ctn_slider">
        <Flickity
          className={'flickity'}
          elementType={'div'}
          options={flickityOptions}
          disableImagesLoaded={false}
          reloadOnUpdate={true}
          flickityRef={c => (this.flkty = c)}
        >
          {slides.map((slide, i) => (
            <div className="slide" key={i}>
              <div
                className="imgcon"
                style={{ backgroundImage: 'url(' + slide.image + ')' }}
              />
              {type === 'haendler' ? (
                <div className="ctncon">
                  <p className="h2">
                    <strong>{slide.title}</strong>, {slide.city}
                  </p>
                  <p className="alt">{slide.description}</p>
                  <div className="metacon">
                    <a
                      className="ion-ios-telephone"
                      href={`tel:${slide.phone}`}
                    >
                      {slide.phone}
                    </a>
                    <br />
                    <a
                      className="ion-android-mail"
                      href={`mailto:${slide.mail}`}
                    >
                      {slide.mail}
                    </a>
                    <div className="buttons">
                      {slide.route && (
                        <a
                          className="btn btn-default btn-noarrow"
                          href={slide.route}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Route planen
                        </a>
                      )}
                      {slide.link && (
                        <a
                          className="btn btn-primary btn-noarrow"
                          href={slide.link}
                        >
                          Mehr erfahren
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="ctncon"
                  dangerouslySetInnerHTML={{ __html: slide.text }}
                />
              )}
            </div>
          ))}
        </Flickity>
      </div>
    )
  }
}

ImageCtnSlider.propTypes = {
  type: PropTypes.string.isRequired,
  slides: PropTypes.array.isRequired,
}

export default ImageCtnSlider
