import React from 'react'
import withSuccess from '../../higher-order-components/withSuccess'
import { formPropTypes } from 'redux-form'
import Success from './Success'
import NetworkError from '../FormControls/NetworkError'
import { ValidationMessagesContact as ValidationMessages } from '../FormControls/ValidationMessages'
import Button from '../FormControls/Button/component'
import FormField from './FormField'
import TextField from '../FormControls/TextField'
import Radios from '../FormControls/Radios'
import Toggle from '../FormControls/Toggle'
import l10n from '../../shared/l10n'

const {
  forms: { submit },
} = l10n

const subjectOptions = [
  {
    value: 'Matratzen testen',
  },
  {
    value: 'Projektanfrage',
  },
  {
    value: 'Rückrufbitte',
  },
  {
    value: 'Sonstiges',
  },
]

class ContactForm extends React.Component {
  render() {
    const { handleSubmit, submitting, invalid, error } = this.props

    const submittingClass = submitting ? 'submitting' : ''

    return (
      <form
        className={`dhsv_form contact ${submittingClass}`}
        onSubmit={handleSubmit}
      >
        <NetworkError />
        <ValidationMessages error={error} />
        <div className="row">
          <div className="col-12 col-lg-8">
            <FormField
              name="subject"
              options={subjectOptions}
              component={Radios}
            />
          </div>
          <div className="col-12 col-lg-4">
            <FormField
              name="type"
              component={Toggle}
              option1={'privat'}
              option2={'gewerblich'}
            />
          </div>
        </div>
        <div className="row inputs">
          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col-12 col-lg-6">
                <FormField
                  name="firstname"
                  component={TextField}
                  maxLength={40}
                />
              </div>
              <div className="col-12 col-lg-6">
                <FormField
                  name="lastname"
                  component={TextField}
                  maxLength={40}
                />
              </div>
              <div className="col-12 col-lg-6">
                <FormField name="phone" component={TextField} maxLength={40} />
              </div>
              <div className="col-12 col-lg-6">
                <FormField name="email" component={TextField} maxLength={80} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <FormField
              name="message"
              component={TextField}
              maxLength={80}
              multiline
            />
          </div>
        </div>
        <div className="buttons">
          <Button type="submit" disabled={submitting || invalid}>
            {submit}
          </Button>
        </div>
      </form>
    )
  }
}

ContactForm.propTypes = {
  ...formPropTypes,
}

export default withSuccess(Success)(ContactForm)
