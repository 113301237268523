import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Form from './form'
import { submitContactForm } from '../../redux/modules/contactForm'

const WithData = connect(null, dispatch =>
  bindActionCreators(
    {
      onSubmit: submitContactForm,
    },
    dispatch
  )
)(Form)

export default WithData
