import React from 'react'
import PropTypes from 'prop-types'
import { PanelGroup, Panel } from 'react-bootstrap'

const accId = (Math.random() * (99999999 - 10000000 + 1)) << 0

// function b64EncodeUnicode(str) {
//   return btoa(
//     encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
//       return String.fromCharCode(parseInt(p1, 16))
//     })
//   )
// }

function b64DecodeUnicode(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )
}

class Accordion extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startKey: this.props.firstopen ? 0 : Number.MAX_SAFE_INTEGER,
    }
  }

  render() {
    const { items, multiopen, base64 } = this.props
    const { startKey } = this.state

    return (
      <div className="dhsv_accordion">
        <PanelGroup
          id={'dhsv_accordion--' + accId}
          accordion={!multiopen}
          defaultActiveKey={startKey}
        >
          {items.map((item, i) => (
            <Panel eventKey={i} key={i}>
              <Panel.Heading>
                <Panel.Title toggle>
                  <div dangerouslySetInnerHTML={{ __html: item.title }} />
                </Panel.Title>
              </Panel.Heading>
              <Panel.Body collapsible>
                <div
                  dangerouslySetInnerHTML={{
                    __html: base64
                      ? b64DecodeUnicode(item.content)
                      : item.content,
                  }}
                />
              </Panel.Body>
            </Panel>
          ))}
        </PanelGroup>
      </div>
    )
  }
}

Accordion.propTypes = {
  items: PropTypes.array.isRequired,
  firstopen: PropTypes.bool,
  multiopen: PropTypes.bool,
  base64: PropTypes.bool,
}

export default Accordion
