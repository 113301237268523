import React from 'react'
import PropTypes from 'prop-types'
import { loadPostsContinously, selectPost } from '../../redux/modules/posts'
import { updateFilter } from '../../redux/modules/filters'
import GoogleMap from '../GoogleMapHaendler'
import Filters from './Filters'
import LoadingIndicator from '../LoadingIndicator'
import { scrollToAnchor } from '../../util/dom'
import './styles.scss'

class Haendlersuche extends React.Component {
  constructor(props) {
    super(props)
    this.state = { submitted: false, route: null }
    this.loadPosts = this.loadPosts.bind(this)
    this.updateFilter = this.updateFilter.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleSelectPost = this.handleSelectPost.bind(this)
    this.handleRoute = this.handleRoute.bind(this)
    this.submit = this.submit.bind(this)
  }

  loadPosts() {
    return this.props.dispatch(loadPostsContinously())
  }

  updateFilter(name, value) {
    this.props.dispatch(updateFilter(name, value))

    setTimeout(() => {
      const {
        filters: { location },
      } = this.props
      if (location) {
        this.loadPosts()
      }
    }, 0)
  }

  handleLocationChange(location) {
    this.updateFilter('location', location)
  }

  handleSelectPost(id) {
    return this.props.dispatch(selectPost(id))
  }

  handleRoute(destination) {
    const {
      filters: { geolocation },
    } = this.props
    if (geolocation && destination) {
      const route = {
        origin: geolocation,
        destination: destination,
      }
      this.setState({ route })
    } else {
      this.setState({ route: null })
    }
  }

  submit() {
    this.setState({ submitted: true })
  }

  render() {
    const {
      filters,
      posts: { data: haendler, isLoading, selectedPostId },
      produktgruppen,
    } = this.props

    const { location } = filters

    const { route } = this.state

    return (
      <div className="dhsv_haendlersuche" id="haendlersuche">
        <div className="filters">
          <Filters
            filters={filters}
            onChangeFilter={this.updateFilter}
            produktgruppen={produktgruppen}
          />
          <div>
            <button
              className="btn btn-default btn-noarrow"
              onClick={this.submit}
              disabled={!location}
            >
              Suche
            </button>
          </div>
          <LoadingIndicator loading={isLoading} />
        </div>
        <GoogleMap
          center={location}
          locations={this.props.posts.data}
          onSelectMarker={this.handleSelectPost}
          selectedMarker={selectedPostId}
          route={route}
        />
        {haendler && (
          <div className="haendler">
            <div className="row">
              {haendler.map((post, i) => (
                <div key={i} className="col-12 col-md-6 col-lg-4">
                  <div
                    className={`post ${
                      selectedPostId === post.id ? 'selected' : ''
                    }`}
                    id={'haendler-' + post.id}
                    onClick={() => this.handleSelectPost(post.id)}
                  >
                    <div
                      className="imgcon"
                      style={{
                        backgroundImage: 'url(' + post.fields.image + ')',
                      }}
                    >
                      <div
                        className="backtomap"
                        onClick={() => {
                          scrollToAnchor('haendlersuche')
                        }}
                      >
                        Auf Karte anzeigen
                      </div>
                    </div>
                    <div className="ctncon">
                      <div className="products">
                        {post.fields.produktgruppe &&
                          post.fields.produktgruppe.map((pg, i) => (
                            <span key={i}>{pg}</span>
                          ))}
                      </div>
                      <p
                        className={'yarned h2'}
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered,
                        }}
                      />
                      <div className="metacon">
                        <p>{post.fields.street}</p>
                        <p>
                          {post.fields.zip} {post.fields.city}
                        </p>
                        <p>Telefon: {post.fields.phone}</p>
                        <p>
                          <a
                            href={post.fields.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {post.fields.website}
                          </a>
                        </p>
                      </div>
                      <div className="buttons">
                        {filters.geolocation && (
                          <span
                            onClick={() => {
                              scrollToAnchor('haendlersuche')
                              this.handleRoute(post.location)
                            }}
                            className="btn btn-default btn-noarrow"
                          >
                            Route planen
                          </span>
                        )}
                        {post.fields.hasPage && (
                          <a
                            className="btn btn-primary btn-noarrow"
                            href={post.link}
                          >
                            Mehr erfahren
                          </a>
                        )}
                      </div>
                    </div>
                    {post.fields.isPremium && <span className="premium" />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

Haendlersuche.propTypes = {
  dispatch: PropTypes.func,
  posts: PropTypes.object,
  filters: PropTypes.object,
  produktgruppen: PropTypes.array,
}

export default Haendlersuche
