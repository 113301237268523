import React from 'react'
import PropTypes from 'prop-types'

const withRequired = requiredFields => WrappedComponent => {
  const WithRequired = props => {
    const { name } = props
    const required = requiredFields.includes(name)
    return <WrappedComponent required={required} {...props} />
  }

  WithRequired.propTypes = {
    name: PropTypes.string,
  }
  return WithRequired
}

export default withRequired
