import { createSelector } from 'reselect'

export const REQUEST_FAILED = 'dhsv/nir001/networkError/REQUEST_FAILED'
export const CLEAR_ERROR = 'dhsv/nir001/networkError/CLEAR_ERROR'

const initialState = null

export default (state = initialState, action) => {
  switch (action.type) {
    case REQUEST_FAILED:
      return action.message

    case CLEAR_ERROR:
      return initialState

    default:
  }

  return state
}

export const sHasNetworkError = createSelector(
  s => s.networkError,
  m => !!m
)
