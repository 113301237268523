import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import equals from 'ramda/es/equals'
import './styles.scss'

class Select extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(option) {
    this.props.onChange(option.value)
  }

  render() {
    const { options, value } = this.props
    return (
      <ReactSelect
        className="dhsv_formcontrol_select"
        onChange={this.handleChange}
        options={options}
        value={options.find(option => equals(option.value, value))}
        classNamePrefix="select"
      />
    )
  }
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]).isRequired,
  options: PropTypes.array.isRequired,
}

export default Select
