import React from 'react'
import PropTypes from 'prop-types'
import Accordion from '../Accordion'
import sortBy from 'ramda/es/sortBy'
import compose from 'ramda/es/compose'
import toLower from 'ramda/es/toLower'
import prop from 'ramda/es/prop'
import range from 'ramda/es/range'

class Lexikon extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      startChar: 'A',
      endChar: 'F',
    }

    this.filterByRange = this.filterByRange.bind(this)
  }

  filterByRange(items, startChar, endChar) {
    const alphabet = range(
      startChar.charCodeAt(0),
      endChar.charCodeAt(0) + 1
    ).map(i => String.fromCharCode(i))
    return items.filter(function (item) {
      for (const key in alphabet) {
        if (alphabet[key] === 'A') {
          if (
            item.title.charAt(0) === alphabet[key] ||
            item.title.charAt(0) === 'Ä'
          ) {
            return item
          }
        } else if (alphabet[key] === 'O') {
          if (
            item.title.charAt(0) === alphabet[key] ||
            item.title.charAt(0) === 'Ö'
          ) {
            return item
          }
        } else if (alphabet[key] === 'U') {
          if (
            item.title.charAt(0) === alphabet[key] ||
            item.title.charAt(0) === 'Ü'
          ) {
            return item
          }
        } else {
          if (item.title.charAt(0) === alphabet[key]) {
            return item
          }
        }
      }
    })
  }

  render() {
    const { items } = this.props
    const { startChar, endChar } = this.state

    const sortByStateCaseInsensitive = sortBy(compose(toLower, prop('title')))

    const itemsSortedByAlphabet = sortByStateCaseInsensitive(items)

    const itemsSortedByRange = this.filterByRange(
      itemsSortedByAlphabet,
      startChar,
      endChar
    )

    return (
      <div className="dhsv_lexikon">
        <div className="row">
          <div className="col-12 col-lg-6" />
          <div className="col-12 col-lg-6">
            <div className="filters">
              <div
                className="filter"
                filter-active={startChar === 'A' ? 'true' : 'false'}
                onClick={() => this.setState({ startChar: 'A', endChar: 'F' })}
              >
                <span className="p">A - F</span>
              </div>
              <div
                className="filter"
                filter-active={startChar === 'G' ? 'true' : 'false'}
                onClick={() => this.setState({ startChar: 'G', endChar: 'L' })}
              >
                <span className="p">G - L</span>
              </div>
              <div
                className="filter"
                filter-active={startChar === 'M' ? 'true' : 'false'}
                onClick={() => this.setState({ startChar: 'M', endChar: 'R' })}
              >
                <span className="p">M - R</span>
              </div>
              <div
                className="filter"
                filter-active={startChar === 'S' ? 'true' : 'false'}
                onClick={() => this.setState({ startChar: 'S', endChar: 'Z' })}
              >
                <span className="p">S - Z</span>
              </div>
            </div>
          </div>
        </div>
        <Accordion items={itemsSortedByRange} />
      </div>
    )
  }
}

Lexikon.propTypes = {
  items: PropTypes.array.isRequired,
}

export default Lexikon
