import map from 'ramda/es/map'
import filter from 'ramda/es/filter'
import ifElse from 'ramda/es/ifElse'
import compose from 'ramda/es/compose'
import join from 'ramda/es/join'
import toPairs from 'ramda/es/toPairs'
import identity from 'ramda/es/identity'
import has from 'ramda/es/has'
import prop from 'ramda/es/prop'
import defaultLabels from '../shared/labels'
import l10n from '../shared/l10n'

const {
  forms: { validate_required1, validate_required2, validate_email },
} = l10n

export const validateFileInput = (
  bytes = 1024 * 1024 * 10,
  count = 10
) => files => {
  if (!Array.isArray(files)) {
    return
  }

  if (files.every(({ size }) => size <= bytes) && files.length <= count) {
    return
  }

  const mbytes = bytes / (1024 * 1024)

  return `Maximal ${count} Dateien á ${mbytes} MB erlaubt!`
}

export const validateRequired = (fields, labels = defaultLabels) => values =>
  fields.reduce(
    (acc, fieldName) =>
      !values[fieldName]
        ? {
            ...acc,
            [fieldName]: `Das Feld ${labels[fieldName]} muss ausgefüllt werden.`,
          }
        : acc,
    {}
  )

export const composeValidations = (...validations) => (values, props) => {
  return [...validations].reverse().reduce((carry, validate) => {
    const results = validate(values, props)

    return {
      ...carry,
      ...results,
      _error:
        carry._error || results._error
          ? [
              ...(carry._error ? carry._error : []),
              ...(results._error ? results._error : []),
            ]
          : undefined,
    }
  }, {})
}

// this adds all messages also to the base validations object
// so we know in the individual fields where an error happened
// to add a has-error class
const addErrorsToObj = obj =>
  Array.isArray(obj._error)
    ? obj._error.reduce(function (carry, [key, message]) {
        return {
          ...carry,
          [key]: message,
        }
      }, obj)
    : obj

// does the same as the above, except it returns an array of [k,v] pairs
// to preserve the order of "fields"
export const validateRequiredAsPairs = (
  fields,
  labels = defaultLabels
) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${validate_required1} ${labels[f]} ${validate_required2}`]),
    filter(f => !values[f])
  )(fields)

const emailRegex = /^[a-zA-Z0-9._|\\%#~`=?&/$^*!}{+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,20}$/
const validateEmail = str => emailRegex.test(str)

export const validateEmailFields = (fields, labels = defaultLabels) => values =>
  compose(
    addErrorsToObj,
    ifElse(
      ({ length }) => length <= 0,
      () => ({}),
      _error => ({ _error })
    ),
    map(f => [f, `${labels[f]}: ${validate_email}`]),
    filter(f => !validateEmail(values[f])),
    filter(f => !!values[f])
  )(fields)

export const urlEncode = compose(
  join('&'),
  map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`),
  filter(([, v]) => !!v),
  toPairs
)

export const unwrapValues = map(ifElse(has('value'), prop('value'), identity))
