import React from 'react'
import PropTypes from 'prop-types'
import Radiobuttons from '../Radiobuttons'
import Flickity from 'react-flickity-component'
import './styles.scss'

class Produkteslider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      produktgruppe: '534',
    }

    this.handleChangeProduktgruppe = this.handleChangeProduktgruppe.bind(this)
  }

  componentDidMount() {
    this.flkty.on('ready', () => {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'))
      }, 1000)
    })
  }

  componentDidUpdate() {
    window.dispatchEvent(new CustomEvent('resize'))
  }

  handleChangeProduktgruppe(produktgruppe) {
    this.setState({ produktgruppe })
  }

  render() {
    const { produkte, produktgruppen } = this.props
    const { produktgruppe } = this.state

    let slides = produkte[produktgruppe].items

    const flickityOptions = {
      cellSelector: '.slide',
      draggable: slides.length > 1 ? true : false,
      pageDots: true,
      prevNextButtons: true,
      arrowShape: {
        x0: 8,
        x1: 50,
        y1: 45,
        x2: 51,
        y2: 44,
        x3: 9.8,
      },
      contain: true,
      freeScroll: false,
      autoPlay: 10000,
      pauseAutoPlayOnHover: true,
      wrapAround: true,
    }

    return (
      <div className="dhsv_produkteslider">
        <div className="filters row">
          <div className="col-12">
            <Radiobuttons
              className="radios"
              items={produktgruppen}
              onChange={this.handleChangeProduktgruppe}
              checkedElement={produktgruppe}
            />
          </div>
        </div>
        <Flickity
          className={'flickity'}
          elementType={'div'}
          options={flickityOptions}
          disableImagesLoaded={false}
          reloadOnUpdate={true}
          flickityRef={c => (this.flkty = c)}
        >
          {slides.map((slide, i) => (
            <div className="slide" key={i}>
              <div className="row">
                <div className="col-12">
                  <img src={slide.image} />
                </div>
                <div className="col-12 col-lg-8">
                  <p className="alt">
                    <strong>{slide.title}</strong>
                  </p>
                  <p>{slide.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Flickity>
      </div>
    )
  }
}

Produkteslider.propTypes = {
  produkte: PropTypes.object.isRequired,
  produktgruppen: PropTypes.array.isRequired,
}

export default Produkteslider
