import { SubmissionError, destroy } from 'redux-form'
import toPairs from 'ramda/es/toPairs'
import concat from 'ramda/es/concat'
import map from 'ramda/es/map'
import compose from 'ramda/es/compose'
import { urlEncode } from '../../util/form'
import { CLEAR_ERROR, REQUEST_FAILED } from './networkError'

const serverErrorMessage = [
  ['_server_error', 'Der Server antwortete mit einem Fehler:'],
]

const buildServerErrorsList = compose(
  concat(serverErrorMessage),
  map(([key, message]) => ['_server_error validation', `${key}: ${message}`]),
  toPairs
)

export const submitForm = ({
  formName,
  url,
  data,
  successType,
  failureType,
}) => dispatch => {
  dispatch({ type: CLEAR_ERROR })

  return fetch(url, {
    method: 'POST',
    body: urlEncode(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
  })
    .catch(error => {
      dispatch({
        meta: {
          data,
          error,
          reason: 'network error (no response)',
        },
        type: failureType,
      })

      dispatch({ type: REQUEST_FAILED, message: error.message })

      throw error
    })
    .then(response => {
      if (![200, 400].includes(response.status)) {
        dispatch({
          meta: {
            data,
            response,
            reason: 'network error (with response)',
          },
          type: failureType,
        })

        const message = response.statusText
          ? response.statusText
          : response.status

        dispatch({ type: REQUEST_FAILED, message })

        throw new Error(message)
      }

      return response.json().then(json => {
        if (json.status !== 'ok') {
          dispatch({
            meta: { data, reason: 'validation', response, json },
            type: failureType,
          })

          throw new SubmissionError({ _error: buildServerErrorsList(json) })
        }

        dispatch({
          meta: { data, response, json },
          type: successType,
        })

        dispatch(destroy(formName))

        return { response, json }
      })
    })
}
