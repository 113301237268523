import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import LocationSearchInput from '../FormControls/LocationSearchInput'
import Select from '../FormControls/Select'
import { distanceOptions } from '../../shared/filterData'
import Geolocation from '../Geolocation'

class Filters extends React.Component {
  constructor(props) {
    super(props)

    this.mkHandleChange = this.mkHandleChange.bind(this)
  }

  mkHandleChange(field) {
    return value => {
      this.props.onChangeFilter(field, value)
    }
  }

  render() {
    const { filters, produktgruppen } = this.props

    return (
      <Fragment>
        <div>
          <Select
            onChange={this.mkHandleChange('produktgruppe')}
            value={filters.produktgruppe}
            options={produktgruppen}
          />
        </div>
        <div>
          <LocationSearchInput
            onSelect={this.mkHandleChange('location')}
            onChange={this.mkHandleChange('address')}
            value={filters.address}
          />
        </div>
        <div>
          <Geolocation
            onChange={this.mkHandleChange('location')}
            onChangeInfinite={this.mkHandleChange('geolocation')}
          >
            <span className="btn btn-location btn-default btn-noarrow" />
          </Geolocation>
        </div>
        <div>
          <Select
            onChange={this.mkHandleChange('distance')}
            value={filters.distance}
            options={distanceOptions}
          />
        </div>
      </Fragment>
    )
  }
}

Filters.propTypes = {
  filters: PropTypes.object,
  onChangeFilter: PropTypes.func.isRequired,
  produktgruppen: PropTypes.array,
}

export default Filters
