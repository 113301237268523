import React from 'react'
import PropTypes from 'prop-types'
import Flickity from 'react-flickity-component'
import './styles.scss'

class HeroSlider extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { slides } = this.props

    const flickityOptions = {
      cellSelector: '.slide',
      draggable: slides.length > 2 ? true : false,
      pageDots: slides.length > 1 ? true : false,
      prevNextButtons: true,
      arrowShape: {
        x0: 8,
        x1: 50,
        y1: 45,
        x2: 51,
        y2: 44,
        x3: 9.8,
      },
      contain: true,
      freeScroll: false,
      autoPlay: 5000,
      pauseAutoPlayOnHover: false,
      wrapAround: true,
    }

    return (
      <div className="hero_slider">
        <Flickity
          className={'flickity'}
          elementType={'div'}
          options={flickityOptions}
          disableImagesLoaded={false}
          reloadOnUpdate={true}
          flickityRef={c => (this.flkty = c)}
        >
          {slides.map((slide, i) => (
            <div
              className="slide"
              key={i}
              style={{ backgroundImage: 'url(' + slide.image + ')' }}
            >
              <div
                className="-mobileimg"
                style={{ backgroundImage: 'url(' + slide.image + ')' }}
              />
              <div className="container">
                <div className="row">
                  <div className="col-12 col-lg-2 col-xxl-1" />
                  <div className="col-12 col-lg-6">
                    <a href={slide.link}>
                      <div
                        className="title yarned"
                        dangerouslySetInnerHTML={{ __html: slide.title }}
                      />
                      <span
                        className="p"
                        dangerouslySetInnerHTML={{ __html: slide.description }}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Flickity>
      </div>
    )
  }
}

HeroSlider.propTypes = {
  slides: PropTypes.array.isRequired,
}

export default HeroSlider
