/*
 * conditionally render a success component instead of the enhanced redux-form component
 * */
import React from 'react'
import { formPropTypes } from 'redux-form/es/propTypes'

const withSuccess = SuccessComponent => WrappedComponent => {
  const WithSuccess = props =>
    props.submitSucceeded ? (
      <SuccessComponent {...props} />
    ) : (
      <WrappedComponent {...props} />
    )

  WithSuccess.propTypes = formPropTypes
  return WithSuccess
}

export default withSuccess
