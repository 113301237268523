import '../autoload/jquery.selectric.js'
import { getCookie, setCookie } from '../util/cookie'

export default {
  init() {
    /* eslint-disable */
    console.log(
      '%c © made with ' +
        String.fromCodePoint('0x1f5a4') +
        ' and ' +
        String.fromCodePoint('0x1f9e0') +
        ' by 360VIER GmbH ',
      'color: #fff; background-color: #81ba49; padding: 5px 15px; border-radius: 5px;'
    )
    /* eslint-enable */

    Menu360()
    navScroll()
    dhsvFieldToggle()
    dhsvMultipleSelect()
    hotelDisclaimer()

    function Menu360() {
      $('header.--mobile .toggleMenu').on('click tap', function () {
        $('html').toggleClass('menu360-open')
        $(this).toggleClass('active')
        $('#menu360').toggleClass('open-toggle')
      })

      $(window).on('resize', function () {
        var w = $(this)
        if (w.width() > 991) {
          $('html').removeClass('menu360-open')
          $('header.--mobile .toggleMenu').removeClass('active')
          $('#menu360').removeClass('open-toggle')
        }
      })

      $('#menu360 ul li.menu-item-has-children > a').on('click tap', function (
        e
      ) {
        e.preventDefault()
        if ($(this).parent().hasClass('open')) {
          $(this).next().animate(
            {
              height: 0,
            },
            500
          )
          $(this).parent().removeClass('open')
        } else {
          $(this)
            .next()
            .animate(
              {
                height: $(this).next().get(0).scrollHeight,
              },
              500,
              function () {
                $(this).height('auto')
              }
            )
          $(this).parent().addClass('open')
        }
      })
    }

    function navScroll() {
      var lastScrollTop = 0
      const nav = $('header.--desktop')

      $(window).scroll(function () {
        const scrolltop = window.pageYOffset

        if (scrolltop === lastScrollTop) {
          return
        }

        const distance = 105

        if (scrolltop < distance) {
          nav.removeClass('sticky')
        } else {
          nav.addClass('sticky')
        }

        lastScrollTop = scrolltop
      })
    }

    function dhsvFieldToggle() {
      $(
        '.gform_wrapper form .gform_body .gform_fields .gfield.dhsv_field_toggle .ginput_container ul'
      ).each(function () {
        const parent = $(this)
        const firstChild = parent.find('li:first-child')
        const lastChild = parent.find('li:last-child')
        firstChild.after('<li class="gtoggle"></li>')
        const toggle = parent.find('.gtoggle')

        if (lastChild.find('input:checked').length > 0) {
          parent.addClass('is-toggled')
        }

        $(document).on('click tap', function (e) {
          if (e.target === toggle[0]) {
            if (parent.hasClass('is-toggled')) {
              firstChild.find('input').prop('checked', true)
              parent.removeClass('is-toggled')
            } else {
              lastChild.find('input').prop('checked', true)
              parent.addClass('is-toggled')
            }
          }
        })

        firstChild.on('click tap', function () {
          parent.removeClass('is-toggled')
        })

        lastChild.on('click tap', function () {
          parent.addClass('is-toggled')
        })
      })
    }

    function dhsvMultipleSelect() {
      $(
        '.gform_wrapper form .gform_body .gform_fields .gfield .ginput_container.ginput_container_multiselect select'
      ).each(function () {
        $(this).selectric({
          labelBuilder: function (currItem) {
            if (!currItem.selected) {
              return '-'
            }
            return currItem.text
          },
        })
      })
    }

    jQuery(document).on('gform_post_render', function () {
      dhsvFieldToggle()
      dhsvMultipleSelect()
    })

    function hotelDisclaimer() {
      const $disclaimer = $('.disclaimer')
      if ($disclaimer.length > 0) {
        if (getCookie('hotelDisclaimerClosed')) {
          $disclaimer.remove()
        } else {
          $disclaimer.removeClass('hidden')

          $disclaimer.find('button').on('click tap', function () {
            $disclaimer.remove()
            setCookie('hotelDisclaimerClosed', 1, 1)
          })
        }
      }
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
}
