import React from 'react'
import PropTypes from 'prop-types'
import GoogleMapReact from 'google-map-react'
import { GOOGLE_MAPS_API_KEY } from '../../shared/constants'
import { scrollToAnchor } from '../../util/dom'

const MapMarker = ({ id, onSelect, selectedMarker }) => (
  <div
    className={`google_maps_marker ${id === selectedMarker ? 'selected' : ''}`}
    onClick={() => onSelect(id)}
  >
    <div className="pin" />
    <div className="pulse" />
  </div>
)

MapMarker.propTypes = {
  id: PropTypes.number,
  onSelect: PropTypes.func,
  selectedMarker: PropTypes.number,
}

class GoogleMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      center: [51.48839, 6.8675113],
      zoom: 12,
      oldDestination: null,
    }

    this.mapRef = React.createRef()

    this.handleChange = this.handleChange.bind(this)
    this.handleSelectMarker = this.handleSelectMarker.bind(this)
    this.handleApiLoaded = this.handleApiLoaded.bind(this)
    this.setRoute = this.setRoute.bind(this)
  }

  componentDidUpdate() {
    this.setRoute()
  }

  handleChange({ center, zoom }) {
    this.setState({ center, zoom })
    const { onLocationChange } = this.props

    onLocationChange && onLocationChange(center)
  }

  handleSelectMarker(id) {
    scrollToAnchor('haendler-' + id)
    const { onSelectMarker } = this.props
    onSelectMarker && onSelectMarker(id)
  }

  handleApiLoaded(map, maps) {
    // https://developers.google.com/maps/documentation/javascript/directions
    this.directionsService = new maps.DirectionsService()
    this.directionsDisplay = new maps.DirectionsRenderer({
      preserveViewport: false,
      suppressMarkers: true,
    })
    this.directionsDisplay.setMap(map)
  }

  setRoute() {
    const { route } = this.props
    const { oldDestination } = this.state
    const google = this.mapRef.current
    const { directionsService, directionsDisplay } = this

    if (route && oldDestination !== route.destination) {
      /* let bounds = new google.maps_.LatLngBounds()
      bounds.extend(new google.maps_.LatLng(route.origin.lat, route.origin.lng))
      bounds.extend(
        new google.maps_.LatLng(route.destination.lat, route.destination.lng)
      ) */

      directionsService.route(
        {
          origin: route.origin,
          destination: route.destination,
          travelMode: google.maps_.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps_.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response)
            //google.map_.fitBounds(bounds)
            this.setState({ oldDestination: route.destination })
          } else {
            console.error(`error fetching directions: ${status}`) //eslint-disable-line
          }
        }
      )
    }
  }

  render() {
    const {
      locations,
      center: forceCenter,
      zoom: forceZoom,
      selectedMarker,
    } = this.props
    const { center, zoom } = this.state

    const finalCenter = forceCenter
      ? [forceCenter.lat, forceCenter.lng]
      : center

    return (
      <div className={`google_maps_wrapper`}>
        <GoogleMapReact
          ref={this.mapRef}
          bootstrapURLKeys={{
            key: GOOGLE_MAPS_API_KEY,
          }}
          options={function (maps) {
            return {
              zoomControlOptions: {
                style: maps.ZoomControlStyle.SMALL,
                position: maps.ControlPosition.LEFT_TOP,
              },
              styles: [
                {
                  stylers: [
                    { saturation: -100 },
                    { gamma: 1.3 },
                    { lightness: 6 },
                    { visibility: 'on' },
                  ],
                },
              ],
            }
          }}
          center={finalCenter}
          zoom={forceZoom || zoom}
          onChange={this.handleChange}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
        >
          {Array.isArray(locations)
            ? locations.map(({ location: { lat, lng }, ...other }, i) => (
                <MapMarker
                  {...other}
                  key={i}
                  lat={lat}
                  lng={lng}
                  onSelect={this.handleSelectMarker}
                  selectedMarker={selectedMarker}
                />
              ))
            : null}
        </GoogleMapReact>
      </div>
    )
  }
}

GoogleMap.propTypes = {
  onLocationChange: PropTypes.func,
  onSelectMarker: PropTypes.func,
  selectedMarker: PropTypes.number,
  zoom: PropTypes.number,
  center: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      location: PropTypes.shape({
        lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    })
  ),
  route: PropTypes.object,
}

export default GoogleMap
