export const distanceOptions = [
  1,
  2,
  3,
  4,
  5,
  10,
  20,
  30,
  40,
  50,
  100,
  200,
  300,
  400,
  500,
  1000,
].map(value => ({ value, label: `${value} km` }))
