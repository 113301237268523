import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

class ImageStory extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeStory: 0,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(activeStory) {
    this.setState({ activeStory })
  }

  render() {
    const { stories, isSM } = this.props
    const { activeStory } = this.state

    return (
      <div className="dhsv_imagestory">
        {!isSM ? (
          <div className="flex">
            <div
              className={`${activeStory === 0 ? 'active' : ''}`}
              onClick={() => this.handleClick(0)}
            >
              <div
                className="yarned"
                dangerouslySetInnerHTML={{ __html: stories[0].title }}
              />
              <div
                className="bgcon"
                style={{ backgroundImage: 'url(' + stories[0].image + ')' }}
              />
            </div>
            <div
              className={`${activeStory === 1 ? 'active' : ''}`}
              onClick={() => this.handleClick(1)}
            >
              <div
                className="yarned"
                dangerouslySetInnerHTML={{ __html: stories[1].title }}
              />
              <div
                className="bgcon"
                style={{ backgroundImage: 'url(' + stories[1].image + ')' }}
              />
            </div>
            <div
              className={`textcon ${activeStory === 0 ? 'active' : ''}`}
              dangerouslySetInnerHTML={{ __html: stories[0].text }}
            />
            <div
              className={`textcon ${activeStory === 1 ? 'active' : ''}`}
              dangerouslySetInnerHTML={{ __html: stories[1].text }}
            />
          </div>
        ) : (
          <React.Fragment>
            {stories.map((story, i) => (
              <div key={i} className="flex">
                <div className="active">
                  <div
                    className="yarned"
                    dangerouslySetInnerHTML={{ __html: story.title }}
                  />
                  <div
                    className="bgcon"
                    style={{ backgroundImage: 'url(' + story.image + ')' }}
                  />
                </div>
                <div
                  className="textcon active"
                  dangerouslySetInnerHTML={{ __html: story.text }}
                />
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
    )
  }
}

ImageStory.propTypes = {
  isSM: PropTypes.bool,
  stories: PropTypes.array.isRequired,
}

export default ImageStory
