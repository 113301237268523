import React from 'react'
import PropTypes from 'prop-types'
import withDebouncedProp from '../../higher-order-components/withDebouncedProp'
import './style.scss'

const LoadingIndicator = ({ loading }) =>
  loading ? <i className="loading_indicator ion-load-c ion-spin" /> : null

LoadingIndicator.propTypes = {
  loading: PropTypes.bool,
}

export default withDebouncedProp()(LoadingIndicator)
