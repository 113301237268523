import reduxForm from 'redux-form/es/reduxForm'
import {
  composeValidations,
  validateEmailFields,
  validateRequiredAsPairs,
} from '../../util/form'
import requiredFields from './requiredFields'

import Component from './component'

const validate = composeValidations(
  validateEmailFields(['email']),
  validateRequiredAsPairs(requiredFields)
)

const WithForm = reduxForm({
  form: 'contact',
  validate: validate,
  initialValues: {
    subject: 'Matratzen testen',
    type: 'privat',
  },
})(Component)

export default WithForm
