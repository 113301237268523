import React from 'react'
import PropTypes from 'prop-types'

const Radiobutton = ({ value, label, checkedElement, onChange }) => (
  <label
    className={checkedElement === value ? 'active' : ''}
    data-title={label}
  >
    <input
      checked={checkedElement === value}
      type="radio"
      onChange={onChange}
    />
    {label}
  </label>
)

Radiobutton.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  checkedElement: PropTypes.string,
  onChange: PropTypes.func,
}

class Radiobuttons extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidUpdate() {}

  handleChange(v) {
    return e => {
      const { onChange } = this.props
      if (e.target.checked) {
        onChange(v)
      }
    }
  }

  render() {
    const { className, items, checkedElement } = this.props

    return (
      <div className={className}>
        {items.map((item, i) => (
          <Radiobutton
            key={i}
            {...item}
            checkedElement={checkedElement}
            onChange={this.handleChange(item.value)}
          />
        ))}
      </div>
    )
  }
}

Radiobuttons.propTypes = {
  className: PropTypes.string,
  items: PropTypes.array.isRequired,
  checkedElement: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}

export default Radiobuttons
