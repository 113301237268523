import { connect } from 'react-redux'
import Component from './component'

const WithData = connect(
  state => ({
    filters: state.filters,
    posts: state.posts,
  }),
  dispatch => ({ dispatch })
)(Component)

export default WithData
