import React from 'react'
import PropTypes from 'prop-types'

const NetworkError = ({ hasNetworkError }) =>
  hasNetworkError && (
    <p>
      Fehler beim Senden des Formulars: bitte überprüfen Sie Ihre
      Internetverbindung und versuchen es zu einem späteren Zeitpunkt erneut!
    </p>
  )

NetworkError.propTypes = {
  hasNetworkError: PropTypes.bool,
}
export default NetworkError
