import { submitForm } from './form'
import { WP_AJAX_URL } from '../../shared/constants'

const SUBMIT_FORM = 'dhsv/nir001/contactForm/SUBMIT_FORM'
const SUBMIT_FORM_SUCCESS = 'dhsv/nir001/contactForm/SUBMIT_FORM_SUCCESS'
const SUBMIT_FORM_FAILURE = 'dhsv/nir001/contactForm/SUBMIT_FORM_FAILURE'

export const submitContactForm = data => dispatch => {
  dispatch({ type: SUBMIT_FORM, meta: { data } })

  return dispatch(
    submitForm({
      formName: 'contact',
      url: `${WP_AJAX_URL}?action=send_contactform`,
      data,
      successType: SUBMIT_FORM_SUCCESS,
      failureType: SUBMIT_FORM_FAILURE,
    })
  ).then()
}
