import React, { Component } from 'react'
import PropTypes from 'prop-types'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { devMode } from '../../../util/env'

class LocationSearchInput extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleChange(address) {
    this.props.onChange(address)
  }

  handleSelect(address) {
    this.setState({ address })
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        this.props.onSelect(latLng)
        if (devMode) console.log('Success', latLng) //eslint-disable-line
      })
      .catch(error => {
        this.props.onSelect(null)
        if (devMode) console.error('Error', error) //eslint-disable-line
      })
  }

  handleBlur(e) {
    if (e.currentTarget !== this) {
      this.handleSelect(this.props.value)
    }
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.value}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={{
          componentRestrictions: { country: 'de' },
          types: ['(cities)'],
        }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="filters--places-autocomplete">
            <input
              {...getInputProps({
                placeholder: 'Ort suchen ...',
                className: '',
              })}
              onBlur={e => {
                this.handleBlur(e)
                getInputProps().onBlur()
              }}
            />
            <div>
              {loading && <div>Loading...</div>}
              {suggestions.map((suggestion, idx) => {
                return (
                  <div
                    key={idx}
                    {...getSuggestionItemProps(suggestion, {
                      className: suggestion.active
                        ? 'suggestion-item--active'
                        : 'suggestion-item',
                    })}
                  >
                    <span>{suggestion.formattedSuggestion.mainText}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

LocationSearchInput.propTypes = {
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.string,
}

export default LocationSearchInput
