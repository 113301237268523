import React from 'react'
import PropTypes from 'prop-types'

const ValidationMessages = ({ errors }) =>
  errors.map(([field, message], key) => (
    <p key={key} className={`error field_${field}`}>
      {message}
    </p>
  ))

ValidationMessages.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
}

export default ValidationMessages
