/*
 * provide the wrapped component with a debounced version of prop,
 * the default predicate function only debounces when the value of prop changes to false
 * */
import React from 'react'

const defaultPredicate = ({ prevValue, value }) =>
  prevValue === true && value === false

const withDebouncedProp = ({
  prop = 'loading',
  delay = 1000,
  predicate = defaultPredicate,
} = {}) => WrappedComponent => {
  class WithDebouncedProp extends React.PureComponent {
    constructor(props) {
      super(props)
      this.state = {
        [prop]: props[prop],
        timeoutID: null,
      }
    }

    componentDidUpdate({ [prop]: prevValue }) {
      const {
        props: { [prop]: value },
        state: { timeoutID },
      } = this

      if (prevValue !== value) {
        clearTimeout(timeoutID)
        if (predicate({ prevValue, value }))
          this.setState({
            timeoutID: setTimeout(
              () => this.setState({ [prop]: value }),
              delay
            ),
          })
        else this.setState({ [prop]: value })
      }
    }

    render() {
      return (
        <WrappedComponent {...this.props} {...{ [prop]: this.state[prop] }} />
      )
    }
  }

  return WithDebouncedProp
}

export default withDebouncedProp
