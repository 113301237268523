// params has to contain one of address,location or placeId
// docs: https://developers.google.com/maps/documentation/javascript/geocoding
export const geocode = params => {
  const geocoder = new window.google.maps.Geocoder()
  const OK = window.google.maps.GeocoderStatus.OK

  return new Promise(function (resolve, reject) {
    geocoder.geocode(params, (results, status) => {
      if (status !== OK) {
        reject(status)
      }
      resolve(results)
    })
  })
}

export const cityByLocation = location =>
  geocode({ location })
    .then(results =>
      results.reduce((acc, el) => {
        if (acc) return acc
        const addressComponent = el.address_components.find(component =>
          component.types.includes('locality')
        )
        return addressComponent ? addressComponent.long_name : null
      }, null)
    )
    .catch(error => console.error('Error', error)) //eslint-disable-line
