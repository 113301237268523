import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

class Modelle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTab: 0,
    }
  }

  render() {
    const { tabs } = this.props
    const { activeTab } = this.state

    const t = tabs[activeTab]

    return (
      <div className="dhsv_modelle">
        <div className="--nav">
          {tabs.map((tab, i) => (
            <div className={activeTab === i ? 'active' : ''} key={i}>
              <p className="h2" onClick={() => this.setState({ activeTab: i })}>
                {tab.title}
              </p>
            </div>
          ))}
        </div>
        <div className="--content">
          <p className="description">{t.text}</p>
          <div className="holder">
            <img src={t.image} />
            {t.numbers.map((n, i) => (
              <span key={i} style={{ top: n.hotspot[0], left: n.hotspot[1] }}>
                {i + 1}
              </span>
            ))}
          </div>
          <div className="nmbrs">
            {t.numbers.map((n, i) => (
              <div key={i}>
                <p>
                  <span>{i + 1}</span>
                  {n.text}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
}

Modelle.propTypes = {
  tabs: PropTypes.array.isRequired,
}

export default Modelle
