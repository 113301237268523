import React from 'react'
import PropTypes from 'prop-types'

class Geolocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = { position: null }
    this.handleClick = this.handleClick.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
  }

  handleClick() {
    if (!('geolocation' in navigator)) {
      return
    }

    navigator.geolocation.getCurrentPosition(this.handleLocationChange)
  }

  handleLocationChange(position) {
    if (position) {
      this.setState({ position })
    }
    const {
      coords: { latitude, longitude },
    } = position

    this.props.onChange({ lat: latitude, lng: longitude })
    this.props.onChangeInfinite({ lat: latitude, lng: longitude })
  }

  render() {
    const hasLocation = this.state.position ? ' has-location' : ''

    return (
      <div
        className={`filters--geolocation${hasLocation}`}
        onClick={this.handleClick}
      >
        {this.props.children}
      </div>
    )
  }
}

Geolocation.propTypes = {
  onChange: PropTypes.func.isRequired,
  onChangeInfinite: PropTypes.func.isRequired,
  children: PropTypes.node,
}

export default Geolocation
